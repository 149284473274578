import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getStatus } from "../Apis";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./store";
import { postUpdateFeedback } from "Apis";
const server = process.env.REACT_APP_STAGE_SERVER;

export const counterSlice = createSlice({
  name: "counter",
  initialState: {
    feedbackStatus: "",
    isLoading: true,
    rating: false,
    number: null,
    animation: false,
    share: false,
    thankYou: false,
    url: "",
  },
  reducers: {
    feedbackStatusLoad: (state, action) => {
      const { loader, rating, animation, share, thankYou } = action.payload;
      // console.log("load success?", action);
      state.isLoading = loader;
      state.rating = rating;
      state.animation = animation;
      state.share = share;
      state.thankYou = thankYou;
    },
    feedbackStatusSuccess: (state, action) => {
      const { loader, rating, animation, share, thankYou } = action.payload;
      state.isLoading = loader;
      state.rating = rating;
      state.animation = animation;
      state.share = share;
      state.thankYou = thankYou;
    },
    feedbackStatusFailure: (state, action) => {
      state.isLoading = false;
      state.rating = false;
      state.animation = false;
      state.share = false;
      state.thankYou = false;
    },
    updateUrlSuccess: (state, action) => {
      state.isLoading = false;

      state.url = action.payload;
    },
    updateRatingSuccess: (state, action) => {
      state.isLoading = false;

      state.number = action.payload;
    },
  },
});

export const getFeedbackStatus = (uid) => {
  return async () => {
    const dispatch = AppDispatch();
    dispatch(counterSlice.actions.feedbackStatusLoad());
    try {
      const res = await getStatus(uid);
      dispatch(counterSlice.actions.feedbackStatusSuccess());

      return res.data;
    } catch (error) {
      console.log("Error while calling status api", error);
    }
  };
};

export function getPostUpdateFeedback(value, data) {
  return async () => {
    const dispatch = AppDispatch();
    // console.log("POST UPDATE RES1");
    dispatch(counterSlice.actions.feedbackStatusLoad());
    try {
      const res = await postUpdateFeedback(value, data);
      // console.log("POST UPDATE RES", res);

      dispatch(counterSlice.actions.updateUrlSuccess(res.badgeUrl));
      return res.badgeUrl;
    } catch (error) {
      console.log("Error while calling post update api", error);
    }
  };
}

// Action creators are generated for each case reducer function
export const {
  feedbackStatusLoad,
  feedbackStatusSuccess,
  feedbackStatusFailure,
  updateUrlSucces,
  updateRatingSuccess,
} = counterSlice.actions;

export default counterSlice.reducer;
