import { Box, Button, Typography } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import exportAsImage from "imageDownload/exportAsImage";
import { useNavigate } from "react-router-dom";
import { toPng } from "html-to-image";
import { AppDispatch } from "redux/store";
import { counterSlice } from "redux/counter";
import { useSelector } from "react-redux";

function Share() {
  const { url } = useSelector((state) => state.counter);

  const characterArray = [
    {
      image: "character_1",
      name: "weasley",
      description:
        "He’s a bit awkward, but always wiggles his tail when he sees you! Not the brightest when it comes to tricks, but he’s good at chess.",
    },
    {
      image: "character_2",
      name: "Wanda",
      description:
        "She cares about her kids a lot. Maybe a bit too much! A habitual daydreamer who has a lot of unreal tricks up her sleeve.",
    },
    {
      image: "character_3",
      name: "Euphoria",
      description:
        "She’s a glam queen that constantly polishes her fins, and loves to travel to different oceans to try their local planktons.",
    },
    {
      image: "character_4",
      name: "Wayne",
      description:
        "A badass that roams the ocean at night, fighting evil crabs. He loves lighthouses because something about them call out to him.",
    },
  ];

  const characterNo = 1 + Math.floor(Math.random() * 4);
  // console.log("characterNo", characterNo);

  const uid = localStorage.getItem("uid");
  let navigate = useNavigate();
  const exportRef = useRef();

  const handleDownload = () => {
    exportAsImage(exportRef.current, "test");
  };

  const handleDone = (e) => {
    const dispatch = AppDispatch();
    dispatch(
      counterSlice.actions.feedbackStatusLoad({
        loader: false,
        rating: false,
        animation: false,
        share: false,
        thankYou: true,
      })
    );
    navigate(`/ThankYou`);
  };

  const handleShare = async (e) => {
    const newFile = await toPng(exportRef.current);

    const data = {
      files: [
        new File([newFile], `${characterArray[characterNo - 1].name}.gif`, {
          type: newFile.type,
        }),
      ],
      title: `${characterArray[characterNo - 1].name}`,
      text: `I opted in for InfinityBox on Swiggy and saved a friend!
            #DitchThemDisposables #InfinityBox #PlasticPollution`,
    };

    try {
      if (!navigator.canShare(data)) {
        e.target.style.background = "";
        e.target.style.backgroundColor = "#1BC831";
        console.error("Can't share");
      }

      await navigator.share(data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div
      style={{
        alignSelf: "center",
        justifySelf: "center",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "auto",
        display: "flex",

        width: "300px",
        maxWidth: "700px",
        flexDirection: "column",

        padding: "2rem",
        overflow: "hidden",
        paddingBlock: "2rem",
      }}
    >
      <div style={{ padding: "2rem" }}>
        <img
          style={{
            boxShadow: 3,
            margin: "auto",
            maxHeight: "600px",
            width: "300px",
            maxWidth: "700px",
          }}
          src={"/assets/card.png"}
          alt="image"
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "0.5rem",
          alignItems: "space-evenly",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          onClick={handleShare}
          sx={{ boxShadow: 3 }}
          style={{
            fontSize: "1.2rem",
            fontWeight: "bolder",
            fontFamily: "Poppins",
            width: "7rem",
            height: "3rem",
            borderRadius: "5rem",
            background: `linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)`,
            color: "white",
            padding: 5,
            cursor: "pointer",
            margin: "1rem",
            textAlign: "center",
            textDecoration: "none",
            textTransform: "capitalize",
          }}
        >
          Share
        </Button>
        <Button
          sx={{ padding: 0, margin: "auto", boxShadow: 3 }}
          style={{
            backgroundColor: "black",
            borderRadius: "10rem",
            height: "3.5rem",
            cursor: "pointer",
            width: "1rem",
          }}
          onClick={handleDownload}
        >
          <Icon
            style={{ fontSize: "2rem", color: "white" }}
            icon="charm:download"
          />
        </Button>
        <Button
          onClick={handleDone}
          sx={{ boxShadow: 3 }}
          style={{
            fontSize: "1.2rem",
            fontFamily: "Poppins",
            fontWeight: "bolder",
            width: "7rem",
            margin: "1rem",
            height: "3rem",
            cursor: "pointer",
            borderRadius: "5rem",
            color: "black",
            padding: 10,
            textAlign: "center",
            textTransform: "capitalize",
          }}
        >
          Done
        </Button>
      </div>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          marginBlock: "2rem",
        }}
      >
        <Typography style={{ fontFamily: "Poppins", fontSize: "0.9rem" }}>
          Mission By
        </Typography>
        <div>
          <img
            style={{ width: "105px", height: "45px" }}
            src={"/assets/Infinity_logo_black.png"}
          ></img>
        </div>
      </Box>
    </div>
  );
}

export default Share;
