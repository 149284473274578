import axiosInstance from "./config";
import axios from "axios";
import { AppDispatch } from "redux/store";
import { counterSlice } from "redux/counter";

const server = process.env.REACT_APP_STAGE_SERVER;

export const getStatus = async (uid) => {
  try {
    const dispatch = AppDispatch();
    dispatch(
      counterSlice.actions.feedbackStatusLoad({
        loader: true,
        rating: false,
        animation: false,
        share: false,
        thankYou: false,
      })
    );

    const res = await axios.post(
      `${server}/feedback/feedback_status`,
      { check_Id: uid },
      {
        headers: {},
      }
    );
    console.log(res);
    if (res.data.ratingCode != 0 && res.data.ratingCode != 1) {
      throw new Error("Wrong feedback id");
    }

    dispatch(
      counterSlice.actions.feedbackStatusSuccess({
        loader: false,
        rating: true,
        animation: false,
        share: false,
        thankYou: false,
      })
    );

    return res.data;
  } catch (error) {
    console.log("Error while getting feedback status", error);
  }
};
export const postAccessToken = async () => {
  try {
    // const data = {
    //     "username": "app-admin",
    //     "password": "7u8i9o0p",
    // }
    // console.log("Server", server)
    // const rawResponse = await fetch(`${server}/oauth/access_token`, {
    //     method: 'POST',
    //     headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({ username: "app-admin", password: "7u8i9o0p", })
    // });
    // const content = await rawResponse.json();
    // console.log("Content", content)
    // return content.data.access_token
    return true;
  } catch (error) {
    console.log("Error while fetching access token", error);
  }
};

export const postUpdateFeedback = async (rating, options) => {
  try {
    const uid = localStorage.getItem("uid");
    // console.log("SUBMIT UID ", uid);
    let response = await axiosInstance.post(`feedback/update_feedback`, {
      check_Id: uid,
      rating: `${rating}`,
      feedback_rating_options: options,
    });
    // console.log("response", response);
    return response.data;
  } catch (error) {
    console.log("Error while fetching access token", error);
  }
};

export const getRatingOptions = async () => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.get(`${server}/feedback/feedback_rating_option`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data.data;
  } catch (error) {
    console.log("Error while fetching access token", error);
  }
};
