import React from 'react'
import '../styles/NotFoundPage.css'
import { Card, CardContent, Typography } from '@mui/material'

function NotFoundPage() {
	return (		
		<div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: '100vh' }}>
			<Card style={{}}>
				<CardContent>
					<Typography gutterBottom variant="h3" component="div">
						404
					</Typography>
					<Typography gutterBottom variant="h5" component="div">
						Your look lost!
					</Typography>
					<Typography gutterBottom variant="h6" component="div">
						In the meantime why dont we explore how we can help mother earth together!!
					</Typography>
					<Typography style={{ fontWeight: 'bold' }} variant="h5" >
						Visit <a href="https://getinfinitybox.com/">infinitybox.</a>
					</Typography>
				</CardContent>
			</Card>
		</div>		
	)
}

export default NotFoundPage