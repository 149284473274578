import React from "react";
import "../styles/NotFoundPage.css";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { Button, Card, Chip, Paper } from "@mui/material";

function ThankYou() {
  return (
    <div
      style={{
        alignSelf: "center",
        justifySelf: "center",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "auto",
        display: "flex",
        // maxHeight: "100vh",
        // width: "300px",
        maxWidth: "700px",
        flexDirection: "column",

        padding: "2rem",
        overflow: "hidden",
        paddingBlock: "2rem",
      }}
    >
      <Box style={{ flex: 1, paddingBlock: 10 }}>
        <img
          style={{
            height: "15vh",

            position: "relative",
            overflow: "hidden",
          }}
          src={"/assets/campaign_logo.png"}
        ></img>
      </Box>

      <Typography
        style={{
          fontFamily: "Poppins",
          marginTop: "2rem",
          flex: 1,
          fontWeight: "bolder",
          fontSize: "1.3rem",
        }}
      >
        Changing the earth, <br /> one drop at a time.
      </Typography>
      <div style={{}}>
        <img
          style={{ mixBlendMode: "multiply", width: "250px", height: "280px" }}
          src={"/assets/drop.gif"}
        ></img>
      </div>

      <div style={{ flex: 1 }}>
        <Typography
          style={{
            fontFamily: "Poppins",

            fontWeight: "bolder",
            fontSize: "1.3rem",
          }}
        >
          Thanks for your drop!
        </Typography>
      </div>
      <div style={{ flex: 1 }}>
        <Typography
          style={{
            fontFamily: "Poppins",
            marginTop: "1rem",
            fontWeight: "bold",
            fontSize: "1rem",
          }}
        >
          To know more about us, please do visit{" "}
          <a href="https://getinfinitybox.com/">InfinityBox.</a>
        </Typography>
      </div>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          marginBlock: "2rem",
        }}
      >
        <Typography style={{ fontFamily: "Poppins", fontSize: "0.9rem" }}>
          Mission By
        </Typography>
        <div>
          <img
            style={{ width: "105px", height: "45px" }}
            src={"/assets/Infinity_logo_black.png"}
          ></img>
        </div>
      </Box>
    </div>
  );
}

export default ThankYou;
