import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function Loader() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
      {/* <Card style={{}}>
                <CardContent>
                    <Typography gutterBottom variant="h3" component="div">
                        Thank You
                    </Typography>
                    <Typography style={{fontWeight:'bold'}} variant="body2" color="text.secondary">
                    You have already given a rating for this ordrer.
                    </Typography>
                </CardContent>
            </Card> */}
    </div>
  );
}

export default Loader;
